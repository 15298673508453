@font-face {
  font-family: 'Brandon Text';
  src: url("../assets/fonts/brandon-text_thin.otf") format("truetype");
  font-weight: 100; }

@font-face {
  font-family: 'Brandon Text';
  src: url("../assets/fonts/brandon-text_light.woff2") format("woff2"), url("../assets/fonts/brandon-text_light.woff") format("woff");
  font-weight: 300; }

@font-face {
  font-family: 'Brandon Text';
  src: url("../assets/fonts/brandon-text_regular.woff2") format("woff2"), url("../assets/fonts/brandon-text_regular.woff") format("woff");
  font-weight: normal; }

@font-face {
  font-family: 'Brandon Text';
  src: url("../assets/fonts/brandon-text_bold.woff2") format("woff2"), url("../assets/fonts/brandon-text_bold.woff") format("woff");
  font-weight: bold; }

.scroll-animation {
  opacity: 0; }

.scroll-animation.scroll-animation-print {
  opacity: 1; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation: fadeIn 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 35px, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInBottom {
  animation: fadeInBottom 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -35px, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInTop {
  animation: fadeInTop 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes fadeInBigBottom {
  from {
    opacity: 0;
    transform: translateY(200%); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(35px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

.fadeInRight {
  animation: fadeInRight 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-35px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

.fadeInLeft {
  animation: fadeInLeft 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes fadeInLeftShort {
  from {
    opacity: 0.8;
    transform: translateX(-5px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeInLeftPagination {
  from {
    opacity: 0.8;
    transform: scale(1.1) translateX(4px) translateY(-2px); }
  to {
    opacity: 1;
    transform: scale(1) translateX(0) translateY(0); } }

@keyframes fadeInRightPagination {
  0% {
    opacity: 0.8;
    transform: scale(1.5); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes scaleInTopRight {
  from {
    transform: translateX(100%);
    height: 0;
    opacity: 0; }
  to {
    transform: translateX(0%);
    height: 100%;
    opacity: 1; } }

@keyframes scaleInFade {
  from {
    opacity: 0;
    transform: scale(1.1) translateY(200%); }
  to {
    opacity: 1;
    transform: scale(1) translateY(0%); } }

@keyframes scaleIn {
  from {
    transform: scale(0.65); }
  to {
    transform: scale(1); } }

.scaleIn {
  animation: scaleIn 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes scaleInSmall {
  from {
    transform: scale(0.98); }
  to {
    transform: scale(1); } }

@keyframes scaleOut {
  from {
    transform: scale(1.65); }
  to {
    transform: scale(1); } }

.scaleOut {
  animation: scaleOut 1s cubic-bezier(0.4, 0, 0.2, 1) both; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes translateDownUp {
  from {
    transform: translate3d(0, 0, 0); }
  35% {
    transform: translate3d(0, 0, 0); }
  45% {
    transform: translate3d(0, 20%, 0); }
  65% {
    transform: translate3d(0, 0, 0); }
  75% {
    transform: translate3d(0, 20%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.translateDownUp {
  animation: translateDownUp 2500ms cubic-bezier(0.4, 0, 0.2, 1) both infinite; }

.delay-100 {
  animation-delay: 100ms; }

.delay-200 {
  animation-delay: 200ms; }

.delay-300 {
  animation-delay: 300ms; }

.delay-400 {
  animation-delay: 400ms; }

.delay-500 {
  animation-delay: 500ms; }

.delay-600 {
  animation-delay: 600ms; }

.delay-1000 {
  animation-delay: 1000ms; }

.duration-800 {
  animation-duration: 800ms; }

.duration-8000 {
  animation-duration: 8000ms; }

.ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.f {
  display: flex; }

.f_i {
  display: flex !important; }

.f-i {
  display: inline-flex; }

.fd-c {
  flex-direction: column; }

.fd-rr {
  flex-direction: row-reverse; }

.ai-c {
  align-items: center; }

.ai-fs {
  align-items: flex-start; }

.ai-fe {
  align-items: flex-end; }

.as-fs {
  align-self: flex-start; }

.as-c {
  align-self: center; }

.as-fe {
  align-self: flex-end; }

.jc-c {
  justify-content: center; }

.jc-sb {
  justify-content: space-between; }

.jc-sa {
  justify-content: space-around; }

.jc-fe {
  justify-content: flex-end; }

.fw-w {
  flex-wrap: wrap; }

.fs-0 {
  flex-shrink: 0; }

.fb-100 {
  flex-basis: 100%; }

.fg-1 {
  flex-grow: 1; }

.fg-2 {
  flex-grow: 2; }

.d-ib {
  display: inline-block; }

.container {
  padding-top: 40px;
  padding-bottom: 50px; }
  .container_last {
    padding-bottom: 100px; }

.content {
  padding-left: 34px;
  padding-right: 34px; }

.section-title {
  font-size: 30px;
  font-weight: normal;
  color: #4a4a4a;
  line-height: normal; }

.section-subtitle {
  font-size: 16px;
  font-weight: normal;
  opacity: 0.5;
  line-height: 1.5; }

.title {
  position: relative;
  font-size: 30px;
  font-weight: normal;
  padding-left: 34px;
  padding-right: 34px;
  z-index: 10; }
  @media (max-width: 1024px) {
    .title {
      padding-left: 10px;
      font-size: 24px; } }
  .title::before {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 23px;
    border-radius: 2px;
    background-color: #4a4a4a;
    left: -2px;
    top: 8px; }
    @media (max-width: 1024px) {
      .title::before {
        height: 17px;
        top: 11px;
        left: -4px; } }
    @media (max-width: 520px) {
      .title::before {
        left: -20px; } }
  .title_type_form {
    margin-bottom: 56px; }
  .title_type_simple {
    padding: 0; }
    .title_type_simple::before {
      display: none; }

.click {
  transition-property: transform;
  transition-duration: 250ms;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .click:active {
    transform: scale(0.92); }

.link {
  color: #4a90e2; }
  .link:hover {
    text-decoration: underline; }
    @media (hover: none) {
      .link:hover {
        text-decoration: none; } }

.stripe {
  position: relative; }
  .stripe::before {
    content: "";
    display: block;
    position: relative;
    flex-shrink: 0;
    width: 6px;
    margin-right: 20px;
    background-color: #4a4a4a;
    border-radius: 3px; }
    @media (max-width: 1024px) {
      .stripe::before {
        width: 4px;
        margin-right: 16px; } }

.fields {
  margin-bottom: 50px; }

.checkbox {
  width: 10px;
  height: 10px;
  border: 1px solid #4a4a4a;
  border-radius: 1px; }
  .checkbox_error {
    border-color: #f44336; }

.focus:focus {
  color: #45cb9f !important;
  outline: none; }

.focus_full {
  transition-property: color;
  transition-duration: 250ms;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes runRight {
  from {
    opacity: 0.2;
    width: 0; }
  to {
    opacity: 1;
    width: 33px; } }

@keyframes translateToRight {
  from {
    opacity: 0;
    transform: translate3d(-35px, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.point {
  width: 5px;
  height: 5px;
  background-color: #4a4a4a;
  border-radius: 50%; }
  .point_navigation {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-delay: 0ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 6px;
    position: relative; }
    .point_navigation:last-child::before {
      content: "";
      display: block;
      position: absolute;
      transition-property: background-color;
      transition-duration: 250ms;
      transition-delay: 0ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      top: 2px;
      left: 4px;
      height: 1px;
      width: 33px;
      background-color: #4a4a4a;
      opacity: 0; }
    .point_navigation:last-child::after {
      content: "";
      display: block;
      position: absolute;
      transition-property: background-color;
      transition-duration: 250ms;
      transition-delay: 0ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      top: -1px;
      left: 36px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #4a4a4a;
      opacity: 0; }
  .navigation-link__href:hover .point_closest_link {
    background-color: #45cb9f; }
    @media (hover: none) {
      .navigation-link__href:hover .point_closest_link {
        background-color: #4a4a4a; } }
  .navigation-sub-link__href:hover .point_closest_sub-link {
    background-color: #45cb9f; }
    @media (hover: none) {
      .navigation-sub-link__href:hover .point_closest_sub-link {
        background-color: #4a4a4a; } }
  .navigation-sub-link__href:hover .point_closest_sub-link:last-child::before, .navigation-sub-link__href:hover .point_closest_sub-link:last-child::after {
    background-color: #45cb9f; }
    @media (hover: none) {
      .navigation-sub-link__href:hover .point_closest_sub-link:last-child::before, .navigation-sub-link__href:hover .point_closest_sub-link:last-child::after {
        background-color: #4a4a4a; } }
  .navigation-sub-link__href_active .point_closest_sub-link:last-child::before {
    opacity: 1;
    animation-name: runRight;
    animation-duration: 450ms;
    animation-delay: 0ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .navigation-sub-link__href_active .point_closest_sub-link:last-child::after {
    opacity: 1;
    animation-name: translateToRight;
    animation-duration: 450ms;
    animation-delay: 0ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

body {
  font-family: "Brandon Text", sans-serif;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.5;
  background-color: white !important; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.2; }

p,
ul,
figure {
  margin: 0; }

a {
  user-select: none;
  text-decoration: none; }

ul {
  padding: 0;
  list-style: none; }

address {
  font-style: normal; }

mark {
  background-color: transparent;
  color: inherit; }

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.fb_dialog {
  transform: scale(0.8334) translateY(-26px) translateX(5px) !important;
  transition: all 250ms ease; }

.fb_customer_chat_bubble_pop_in {
  animation-name: scaleInFade !important; }
