.app {
  min-height: 100vh;
  scroll-behavior: smooth;
  display: flex; }
  .app * {
    -webkit-overflow-scrolling: touch; }

.app__main {
  position: relative;
  padding-left: 220px;
  overflow-x: hidden; }
  @media (max-width: 1024px) {
    .app__main {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (max-width: 520px) {
    .app__main {
      padding-left: 30px;
      padding-right: 30px; } }
  @media print {
    .app__main {
      padding-left: 0; } }
