.H1 {
  font-family: "Brandon Text", sans-serif;
  font-size: 44px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .H1 {
      font-size: 32px; } }

.H2 {
  font-family: "Brandon Text", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .H2 {
      font-size: 24px; } }

.H3 {
  font-family: "Brandon Text", sans-serif;
  font-size: 60px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: rgba(74, 74, 74, 0.2); }
  @media (max-width: 1024px) {
    .H3 {
      font-size: 48px; } }

.H4 {
  font-family: "Brandon Text", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  line-height: 0.91;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .H4 {
      font-size: 18px;
      line-height: 1.11; } }

.H5 {
  font-family: "Brandon Text", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .H5 {
      font-size: 16px; } }

.H5Bold {
  font-family: "Brandon Text", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .H5Bold {
      font-size: 16px;
      line-height: 1.13; } }

.H6 {
  font-family: "Brandon Text", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  opacity: 0.6; }
  @media (max-width: 1024px) {
    .H6 {
      font-size: 15px; } }

.P {
  font-family: "Brandon Text", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .P {
      font-size: 12px;
      line-height: 1.33; } }
  @media (max-width: 520px) {
    .P {
      line-height: 1.5; } }

.Caption {
  font-family: "Brandon Text", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .Caption {
      font-size: 11px;
      line-height: 1.45; } }

.TagLg {
  font-family: "Brandon Text", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  display: block;
  color: #71c4a6; }
  @media (max-width: 1024px) {
    .TagLg {
      font-size: 11px;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #86cbb4; } }

.TagSm {
  font-family: "Brandon Text", sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 1.45;
  text-transform: uppercase;
  display: block;
  color: #71c4a6; }
  @media (max-width: 1024px) {
    .TagSm {
      font-size: 10px;
      letter-spacing: 0.5px;
      line-height: 1.2; } }

.View {
  font-family: "Brandon Text", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 1024px) {
    .View {
      font-size: 15px; } }

.ViewHeader {
  font-family: "Brandon Text", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase; }
  @media (max-width: 1024px) {
    .ViewHeader {
      font-size: 14px;
      line-height: 1.43; } }
